import { Modal, message } from "antd";
import Router from "next/router";
import fetch from "isomorphic-unfetch";
import { StrapiApi } from "$ACTIONS/TLCAPI";
import { getQueryVariable } from "$ACTIONS/helper";
import { Cookie } from "$ACTIONS/util";

import { LogPost } from "./Log";

const apiListError = ["/api/Payment/Application/UploadAttachment"];

message.config({
    top: 50,
    maxCount: 1,
});

const REG_CMS_STATIC_ASSET_DOMAINS = ['cache\.uiwhed\.com','cmsapisl[2]?\.fun88\.biz', 'cmsapistag[1-4]?\\.fun88\\.biz'];

export default function request(method, url, body, token, moreConf={}) {
	const localToken = localStorage.getItem('access_token');
	let redirectToken = null;

	localToken && (redirectToken = getQueryVariable('redirectToken'));
	method = method.toUpperCase();
	const IS_GET_METHOD_REQUEST = method === 'GET';
	if (IS_GET_METHOD_REQUEST) {
		body = undefined;
	} else {
		body = body && JSON.stringify(body);
	}
	//区分两种Api CMS/Flash
	let isCms = url.indexOf('/zh-hans/api/v1/') != -1;
    let isCaptcha = url.indexOf('/api/v1.0/') != -1;


    let header;
    header = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Culture: "zh-cn",
        token: "71b512d06e0ada5e23e7a0f287908ac1",
        //Authorization: token == null ? JSON.parse(localToken) || '' : token
    };
    if (token || JSON.parse(localToken)) {
        header["Authorization"] = token || JSON.parse(localToken);
    }
    //仅Flash会添加
    if (!isCms && !isCaptcha) {
        let IS_USE_FvCBffKey = ['?', '&', '&amp;'].some(_ => typeof url==='string' && url.toLowerCase().includes(_ + 'api-version=8.0'))
        if (IS_USE_FvCBffKey) {
        header["x-bff-key"] = 'FvCImRbajDiysV9ttN728w==';
        } else {
        header["x-bff-key"] = moreConf && moreConf.use3uJBffKey ? "3uJfUBFtI0GAPxK8iMzNGg==" : "51EXaTN7NUeCbjnvg95tgA==";
        }
    }

    //Api日志
    let logData = {
        method: method,
        responseStatus: 0,
        request_time: new Date(),
        responseData: null,
    };
    const apiUrl = url + (isCms ? "" : "");

    return timeout_fetch(
        fetch(url, {
            method,
            headers: header,
            body,
        })
    )
        .then((res) => {
            //記下 responseStatus
            if (res && res.status) {
                logData.responseStatus = res.status;
            }

            if (
                res.status === 401 ||
                res.status === 400 ||
                res.status === 503 ||
                res.status === 403 ||
                res.status === 428
            ) {
                const IS_FEAT_MAINTENANCE = res.status === 503;
                if (IS_FEAT_MAINTENANCE) { // <-- ticket1397 & 3004
                    const httpProtocalLength = url.indexOf('https://')===0 ? 8 : 7;
                    const urlWoProtocal = url.substr(httpProtocalLength)
                    const urlPath = url.substr(urlWoProtocal.indexOf('/') + httpProtocalLength)
                    const IS_LOGIN_API = url.includes("/api/Auth/Login");
                    const IS_LIVECHAT_API = url.includes("/api/LiveChat/Url");
                    let IS_WITHDRAWAL_API = [
                        '/api/Balance[\/?]+',
                        '/api/Payment/Transaction/CheckWithdrawalThreshold[\/?]+',
                        '/api/Member/CustomFlag[\/?]+.*flagKey=BankCardVerification',
                        '/api/Payment/Method/Details[\/?]+.*transactionType=Withdrawal',
                        '/api/Member\\?', // see Dev Guide / UI flow
                        '/api/Payment/Methods[\/?]+.*transactionType=Withdrawal',
                        '/api/Payment/MemberBanks[\/?]+.*Withdrawal',
                        '/api/Member/SelfExclusion[\/?]+',
                        ].some(v => v && RegExp('^'+v, 'i').test(urlPath));
                    let IS_DEPOSIT_API = [
                        '/api/Payment/Methods',
                        '/api/Payment/Method/Details',
                        '/api/Payment/Method/Settings',
                        '/api/Payment/Application/BankingHistory'
                        ].some(v => url.includes(v)) && url.toUpperCase().includes('DEPOSIT')
                        || [
                        '/api/Balance[\/?]+',
                        '/api/Member/SelfExclusion[\/?]+',
                        '/api/Announcement/Popup.*optionType=Transfer'
                        ].some(v => v && RegExp('^'+v, 'i').test(urlPath));
                        ;

                    // some API Urls are in difference IS_XXX_API, so we need detect via `moreConf` if have.
                    if (IS_DEPOSIT_API && IS_WITHDRAWAL_API) {
                        // choose IS_DEPOSIT_API=true as default
                        if (!moreConf.IS_WITHDRAWAL_API) { IS_WITHDRAWAL_API = false; } else { IS_DEPOSIT_API = false; }
                    }

                    const IS_REGISTER_API = url.includes("/api/Member/Register");
                    const IS_LOGINNEDMEMBER_API = /\/api\/Member(?!\/Register)/.test(url) && !IS_REGISTER_API && !IS_WITHDRAWAL_API && !IS_WITHDRAWAL_API;
                    const LANG_RE = '[a-zA-Z\-]+';
                    const IS_PROMO_API = RegExp('^/'+LANG_RE+'/api(\/v1\/app|\/v1\/web|\/v1)/promotion', 'i').test(urlPath)
                            || (method==='POST' && RegExp('^/api/Bonus/Application', 'i').test(urlPath))
                            || RegExp('^/'+LANG_RE+'/api(\/v1\/app|\/v1\/web|\/v1)/rebate', 'i').test(urlPath)
                            || RegExp('^/api/Bonus[\/?]+', 'i').test(urlPath)
                            || RegExp('^/api/Promotion/Rebate/RunningDetails', 'i').test(urlPath) // 4379 4380
                            || RegExp('^/api/Promotion/MemberPromoHistories', 'i').test(urlPath) // 4377
                            || RegExp('^/api/Promotion/(Province|District|Town|ShippingAddress|DailyDeals|ManualPromo)', 'i').test(urlPath)
                                                       // ^ check the excel which printscreen in powerpoint
                            ;
                    let maintenanceText = '维护中。', fnOnOk, fnOnCancel, modalKey;
                    let isReturn503RejectPromise=false;
                    let delayShow503Modal = 0; // UAT issue: Maintenance error message should be displayed first. It SHOULD NOT be overlay by other modal
                    window.isOpened503Modal = window.isOpened503Modal || {}; // initial
                    if (IS_LOGIN_API) {
                        maintenanceText = '登录功能正在维护中，暂时不可用。请稍后再试。';
                        modalKey = 'LOGIN503MODAL';
                        isReturn503RejectPromise = true;
                        fnOnOk = fnOnCancel = () => {
                            window.isOpened503Modal = window.isOpened503Modal || {};
                            window.isOpened503Modal['LOGIN503MODAL'] = false
                        };
                    } else if (IS_REGISTER_API) {
                        maintenanceText = '注册功能正在进行维护，暂时无法使用。请稍后再试。';
                        modalKey = 'REGISTER503MODAL';
                        isReturn503RejectPromise = true;
                        window.isOpened503Modal = window.isOpened503Modal || {};
                        window.isOpened503Modal['REGISTER503MODAL'] = false
                    } else if (IS_LOGINNEDMEMBER_API) {
                        maintenanceText = '由于系统正在进行维护，您将被登出。很抱歉给您带来不便。';
                        modalKey = 'LOGNNEDMEMBER503MODAL';
                        isReturn503RejectPromise = true;
                        fnOnOk = fnOnCancel = () => {
                            window.isOpened503Modal = window.isOpened503Modal || {};
                            window.isOpened503Modal['LOGNNEDMEMBER503MODAL'] = false
                            global.globalExit();
                            Router.push('/cn/');
                        }
                    } else if (IS_PROMO_API) {
                        maintenanceText = '优惠功能正在进行维护，暂时无法使用。请稍后再试。';
                        modalKey = 'PROMO503MODAL';
                        isReturn503RejectPromise = true;
                        fnOnOk = fnOnCancel = () => {
                            window.isOpened503Modal = window.isOpened503Modal || {};
                            window.isOpened503Modal['PROMO503MODAL'] = false
                            const IS_AT_PROMO_PAGE = ['/cn/Promotions'].some(v=>window.location.href.includes(v))
                            if (IS_AT_PROMO_PAGE) {
                                Router.push('/cn/'); // goto Home
                            }
                        };
                    } else if (IS_DEPOSIT_API || IS_WITHDRAWAL_API) {
                        isReturn503RejectPromise = true;
                        if (IS_WITHDRAWAL_API) {
                        maintenanceText = '提款功能正在进行维护，暂时无法使用。请稍后再试。';
                        modalKey = 'WITHDRAWAL503MODAL';
                        delayShow503Modal = 1700;
                        }
                        if (IS_DEPOSIT_API) {
                        maintenanceText = '存款功能正在进行维护，暂时无法使用。请稍后再试。';
                        modalKey = 'DEPOSIT503MODAL';
                        }
                        fnOnOk = fnOnCancel = () => {
                            window.isOpened503Modal = window.isOpened503Modal || {};
                            window.isOpened503Modal[IS_DEPOSIT_API ? 'DEPOSIT503MODAL' : 'WITHDRAWAL503MODAL'] = false
                            global.hideWalletModal();
                            Router.push('/cn/')
                        }
                    } else if (IS_LIVECHAT_API) {
                        maintenanceText = '联系客服功能正在进行维护，暂时无法使用。请稍后再试。';
                        modalKey = 'LIVECHAT503MODAL';
                        isReturn503RejectPromise = true;
                        fnOnOk = fnOnCancel = () => {
                            window.isOpened503Modal = window.isOpened503Modal || {};
                            window.isOpened503Modal['LIVECHAT503MODAL'] = false
                        };
                    }

                    let DONT_SHOW_503MODAL_WHEN_INIT_PAGE_LOADED = moreConf.viaInitialPageLoaded; // <-- ticket2201
                    if (DONT_SHOW_503MODAL_WHEN_INIT_PAGE_LOADED) {
                        // alert('DONT_SHOW_503MODAL_WHEN_INIT_PAGE_LOADED:'+DONT_SHOW_503MODAL_WHEN_INIT_PAGE_LOADED)
                    }else if (modalKey
                            && window.isOpened503Modal && !window.isOpened503Modal[modalKey]) {
                            window.isOpened503Modal[modalKey] = true;
                        setTimeout(()=>{
                        Modal.info({
                            icon: "",
                            okText: '我知道了',
                            className: 'commonModal',
                            content: (
                                <div className="inline-link-btn">
                                    <div>
                                        <img src="/cn/img/icons/icon-warn_yellow.png" style={{margin:'auto', display: 'block'}} />
                                    </div>
                                    <div className="content" style={{margin:'30px auto 0',width:'80%',textAlign:'center',fontWeight:500}}>
                                        <p style={{ fontSize: '14px' }}>{maintenanceText}</p>
                                    </div>
                                </div>
                            ),
                            onOk: fnOnOk,
                            onCancel: fnOnCancel
                        });
                        }, delayShow503Modal || 0);
                    }
                    if (maintenanceText && isReturn503RejectPromise) {
                        let json = res.json()
                        return json.then(() => {
                            return new Promise((resolve, reject) => {
                                // handle under MAINTENANCE
                                resolve({IS_FEAT_MAINTENANCE: true})
                            })}
                        );
                    }
                } // end of IS_FEAT_MAINTENANCE
				let json = res.json();
                if (moreConf.IS_STILL_RETURN_JSONVALUE_IF_HTTP4xx5xx && res.status >= 400 && res.status <=599 ) {
                    return json.then(j => Object.assign(
                                            {Json: j}, {Http: res.status}));
                }
				return json.then(Promise.reject.bind(Promise));
			}

            if (res.status === 500) {
				// 根据某些需求，某些api响应500状态码异常情况不需要报错。
				if (!apiListError.some((apiErrVal) => ~url.indexOf(apiErrVal))) {
					// message.error('系统错误！请稍后重试');
					// Router.push('/cn/');
				} else {
					return res.json();
				}
			} else if (res.status === 504) {
				//message.error('网络错误请检查网络后重试！');
				//Router.push('/cn/');
			} else if (res.status === 404) {
				message.error('未找到相关API，请稍后重试！');
			} else {
				if (typeof StrapiApi === 'string' && StrapiApi
					&& typeof url==='string' && url 
					&& url.toLowerCase().indexOf(StrapiApi.toLowerCase()) === 0
					&& IS_GET_METHOD_REQUEST
					&& res.status === 200
					) {
					return res.json().then(j=>{
						try {
							let s = JSON.stringify(j);
							for (let v of REG_CMS_STATIC_ASSET_DOMAINS) {
								let re = RegExp('http(s)?:\/\/' + v, 'gi');
								if (re.test(s)) {
									j = JSON.parse(JSON.stringify(j)
										.replace(re, StrapiApi));
									break;
								 }
							}
						} catch { /* nop */ }
						return j;
					});
				}
				return res.json();
			}
		})
		.then((response) => {
			if (response) {
				logData.responseData = response;
			} else {
				logData.responseData = {};
			}
			// 日志
			LogPost(apiUrl, body, logData);
			return response;
		})
		.catch((error) => {
			logData.error = error;
			//避免日誌拿到空的error
			if (error && JSON.stringify(error) === '{}') {
				let newError = {};
				if (error.message) {
					newError.message = error.message;
				}
				if (error.stack) {
					newError.stack = error.stack;
				}
				if (JSON.stringify(newError) !== '{}') {
					logData.error = newError;
				}
			}
			LogPost(apiUrl, body, logData);
			if (error.redirect) {
				var searchURL = window.location.search;
				searchURL = searchURL.substring(1, searchURL.length);
				var targetPageId = searchURL.split('&')[0].split('=')[1];
				if (error.redirect.Code == 'GEN0003') {
					if (targetPageId) {
						window.location.href = error.redirect.message + '?aff=' + targetPageId;
					} else {
						window.location.href = error.redirect.message;
					}
				}
			}
			// 公共错误处理
			if (apiListError.some((apiErrVal) => ~url.indexOf(apiErrVal)) && error.errorMessage) {
				return Promise.reject(error.errorMessage);
			}

            if (error && error.errors && error.errors.length != 0) {
                const ERRORCODE = error.errors[0].errorCode;

                switch (ERRORCODE) {
                    // case 'GEN0001':
                    // 	// 维护界面（单独拉取Maintain数据）
                    // 	HttpStatus = 3;
                    // 	Router.push('/cn/');
                    // 	break;
                    case "GEN0002":
                        // 不允许访问（地域限制）
                        window.ipErrorDescription = error.errors[0].description;
                        HttpStatus = 4;
                        Router.push("/cn/");
                        break;
                    case "GEN0005":
                    case "GEN0006":
                    case "IDSVR00006":
                    case "VAL99902":
                    case "VAL18014":
                    case "VAL99903":
                        if (!redirectToken) {
                            message.error("请重新登录，访问过期！", 3);
                            setTimeout(() => {
                                global.globalExit();
                                Router.push("/cn/");
                            }, 1500);
                        }
                        // break;
                        return Promise.reject(error);
                    case "MEM00141":
                    case "MEM00140":
                        message.error(
                            error.errors[0].message ||
                                "您的账户已根据账户管理政策关闭了。"
                        );
                        localStorage.setItem(
                            "RestrictAccessCode",
                            error.error_details.Code
                        );
                        Router.push(`/cn/RestrictAccess`);
                        break;
                    case "MEM00145":
                        Nonecsbutton = true;
                        HttpStatus = 4;
                        global.globalExit();
                        Router.push("/cn/");
                        break;
                    case "GEN0008":
                        if (window.location.href.indexOf("Safehouse") > -1) {
                            message.error(error.errors[0].message, 4);
                            global.redirectDomin &&
                                typeof global.redirectDomin === "function" &&
                                setTimeout(() => {
                                    global.redirectDomin();
                                }, 4000);
                        }
                        break;

                    case "MEM00004":
                    case "MEM00059":
                    case "MEM00060":

                    case "SafeHouseLoginFailed":
                        // Modal.info({
                        // 	className: 'confirm-modal-of-public',
                        // 	icon: <div />,
                        // 	title: '登入失败',
                        // 	centered: true,
                        // 	okText: '确认',
                        // 	zIndex: 2000,
                        // 	content: (
                        // 		<div
                        // 			style={{
                        // 				textAlign: 'center'
                        // 			}}
                        // 			dangerouslySetInnerHTML={{
                        // 				__html: error.errors[0].description
                        // 			}}
                        // 		/>
                        // 	)
                        // });
                        // GeetestFaile();
                        // error.json();
                        // break;

                        return Promise.reject(error);
                    //待處理PII問題
                    case "PII00702":
                        Router.push("/cn/RestrictAccess");
                        global.globalBlackListExit();
                        break;
                    case "VAL11056":
                        message.error("提款卡保存失败", 3);
                        break;
                    // 忘記密碼/忘記用戶名
                    case "VAL00001":
                    case "VAL08025":
                    case "VAL08026":
                    case "VAL03012":
                    case "VAL03001":
                    case "MEM00051":
                    case "P109001":
                    case "P109003":
                    case "VAL13014":
                    case "VAL13007":
                    case "VAL08052":
                    case "VAL03016": //重置密码 新密码不能与旧密码相同
                    case "VAL18015": // 手機驗證嘗試超過五次
                    case "VAL18016": // 驗證碼已發送，五分鐘後再嘗試
                    case "VAL18024": // 手機驗證碼失效
                    case "P103001":
                    case "P103114":
                    case "VAL11012":
                    case "SNC0002":
                    case "VERI9999": // usercenter无法生成安全码
                    case "P103106":
                    case "P101103":
                    case "VAL18023":
                    case "VAL18013":
                    case "P103103":
                    case "P101104":
                    case "P103109":
                    case "P103110":
                    case "P103111":
                    case "P103112":
                    case "P103113":
                    case "P103114":
                    case "P103115":
                    case "P101106":
                    case "P101053":
                    case "P101116": // first for ODP ticket2146
                    case "P115001":
                    case "P101019":
                    case "P101017":
                    case "P106003":
                    case "SNC0001":
                    case "SNC0002":
                    case "SNC0003":
                    case "P111001":
                    case "VAL13034":
                    case "VAL13035":
                    case "MEM00061":
                        return error;
                    default:
                        message.error(error.errors[0].description);
                }
            } else if (error.message) {
                if (
                    error.status == "BAD_REQUEST" &&
                    (error.code == "63401" || error.code == "63403") &&
                    error.path == "/api/v1.0/judgement"
                ) {
                    // 登录图形滑动验证 确认滑动坐标的API，验证错误
                    // return error.json();
                    return Promise.reject(error);
                } else {
                    message.error(error.message);
                }
            }
        });
}

// GET 请求
export const get = (url, moreConf) => request('GET', url, undefined, undefined, moreConf);

// POST 请求
export const post = (url, body, token, moreConf) => request("POST", url, body, token, moreConf);
// PATCH 请求
export const patch = (url, body) => request("PATCH", url, body);
// PUT 上传
export const put = (url, body) => request("PUT", url, body);
// DELETE 删除
export const del = (url, body) => request("DELETE", url, body);

export const timeout_fetch = (fetch_promise, timeout = 600000) => {
    let timeout_fn = null;
    let depositLoading = Cookie("loadingTime"); //网页转账提交的loading时间 100秒，
    let timeout_promise = new Promise(function (resolve, reject) {
        timeout_fn = function () {
            reject({ message: "网络错误，请重试" });
        };
    });
    let abortable_promise = Promise.race([fetch_promise, timeout_promise]);
    setTimeout(
        function () {
            timeout_fn();
        },
        depositLoading ? 100 * 1000 : timeout
    );

    return abortable_promise;
};
